import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  name: 'another_bottle',
  data() {
    return {
    };
  },
  created() {
    this.getConfigList('another_bottle_list');
  },

  methods: {

  },
};
